module.exports = {
  sassVariables: {
    primary: '#f7be24',
    secondary: '#003392',
    'navbar-back-rotation': '250deg',
    'navbar-link-rotation': '-170deg',
    'login-background': "url('/images/goldenguardians_bg.jpg')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/gg_logo_yellow.png',
    fullLogo: '/images/gg_logo_yellow.png',
    overviewLogo: '/images/gg_logo_yellow.png',
    faviconLogo: '/images/gg_logo_yellow.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
